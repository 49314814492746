import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { DataService } from '../services/data.service';
// import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/loginService';
import { Login } from '../models/login';

import { Role } from '../models/role';
import { Company } from '../models/company';
import { ReportFilter, Globle } from '../Globle';
import { NbToastrService } from '@nebular/theme';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  ngOnInit() { }
  role: Role;
  username: any = '';
  password: any = '';
  companyId: any = '';
  loginData: any
  loginForm: any;
  loginCheck = false;
  public errorMessage: string = '';
  customSpinner: boolean = false;
  alertBox: boolean = false;
  locales = [];
  currentUrl = "";
  login: Login = new Login();
  companyArr: Company[];
  validate: boolean;
  userArr: Login[];
  attendanceOption =""
  reportFilter: ReportFilter = new ReportFilter();
  constructor(private fb: FormBuilder,
    private route: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService, private datePipe: DatePipe,
    private loginService: LoginService) {

    //   const storedValue = sessionStorage.retrieve('payload'); // Replace 'key' with your actual key
    // if (storedValue !== null) {
    //   sessionStorage.clear();
    // } 
    // sessionStorage.clear();
    this.loginForm = fb.group({
      user: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      companyId: ['', Validators.required]
    });

  }
  msg: any;
  resp;
  get f() { return this.loginForm.controls; }

  async userLogin() {
    // // //console.log("sdasdgb")
    //  //this.toastr.error( 'Login Failed!');
    //  //this.ngxService.start();
    // this.route.navigate(['/pages/welcome']);
    try {
      //  if (this.validate) {
      this.username = this.loginForm.value.user;
      this.password = this.loginForm.value.password;
      this.companyId = this.loginForm.value.companyId
      //this.loginData = { "username": this.username, "password": this.password }
      this.login.loginId = this.username;
      this.login.password = this.password;
      //console.log("payload data this.companyId", this.companyId, " ", this.login.loginId, "  this.login.password", this.login.password);
      this.loginService.userLogin(this.companyId, this.login.loginId, this.login.password).subscribe(response => {
        this.resp = response;
        // //console.log("payload data ", this.resp);
        sessionStorage.setItem('payload', JSON.stringify(this.resp));

        if (this.resp.length > 0 && this.resp[0].status != false) {
          let myPayload = JSON.parse(sessionStorage.getItem('payload'));
          // //console.log("rolid sss", myPayload[0].roleId)

          this.reportFilter.userRole = myPayload[0].roleId
          this.attendanceOption = myPayload[0].attendanceOption; 
          this.ngxService.stop();

          if (myPayload[0].changePwdFlag) {
            if (myPayload[0].roleId == 1002) {
              // if (myPayload[0].empStatus) {
              // if (myPayload[0].lastWorkingDate != null && myPayload[0].lastWorkingDate != "") {
              //   let date = new Date();
              //   let lastWorkingDate = new Date(myPayload[0].lastWorkingDate);
              //   let currentDate1 = this.datePipe.transform(date, 'yyyy-MM-dd');
              //   let joiningDate1 = this.datePipe.transform(lastWorkingDate, 'yyyy-MM-dd');
              //   if (currentDate1 <= joiningDate1) {
              //     this.route.navigate(['/pages/hrms/per-day-attendance']);
              //   } else {
              //     // alert("")
              //   }
              // } else {
              //     this.route.navigate(['/pages/hrms/per-day-attendance']);
              //   // }
              // } else {
              //   this.route.navigate(['/pages/welcome-page']);
              //   // this.route.navigate(['/pages/hrms/per-day-attendance']);
              // }
              if (myPayload[0].empStatus) {
                if (myPayload[0].lastWorkingExpiryFlag) {
                  alert("Last Working Date is Expired !!")
                  sessionStorage.clear();
                } else {
                  // this.route.navigate(['/pages/hrms/per-day-attendance']);
                  this.setHomePage();
                }
              }else{
                this.route.navigate(['/pages/welcome-page']);
              }

            } else if (myPayload[0].roleId == 1001 || myPayload[0].roleId == 1007) {
              if (myPayload[0].lastWorkingExpiryFlag) {
                alert("Last Working Date is Expired!!")
                sessionStorage.clear();
              } else {
                // this.route.navigate(['/pages/welcome-page']);
                this.setHomePage();
              }
            }else if(myPayload[0].roleId == 1008){
              this.route.navigate(['/pages/welcome-page']);
            } else {
              // this.route.navigate(['/pages/welcome']);
              if (myPayload[0].empStatus) {
                if (myPayload[0].lastWorkingExpiryFlag) {
                  alert("Last Working Date is Expired !!")
                  sessionStorage.clear();
                } else {
                  // this.route.navigate(['/pages/hrms/per-day-attendance']);
                    this.setHomePage();
                }
              }else{
                this.route.navigate(['/pages/welcome-page']);
              }
            }
          } else {
            this.route.navigate(['/pages/settings/change-password']);
          }
          this.reportFilter.userRole = 0;
        }
        else {
          alert("Invalid Credentials")
          //this.ngxService.stop();
          //this.toastr.error('Login Failed!');
        }
      })

    }
    catch (e) {
      //console.log(e);
    }
    finally {

    }
  }

  setHomePage(){
    if((this.attendanceOption== null && this.attendanceOption =="") || this.attendanceOption !== "By Biometric" ){
      this.route.navigate(['/pages/hrms/per-day-attendance']);
    }else{
      this.route.navigate(['/pages/welcome-page']);

    }
  }

  getCompany() {
    var utc = new Date().toJSON().slice(0, 10)
    let comp = parseInt(this.loginForm.value.companyId);
    // //console.log("comp", comp);

    this.loginService.searchCompanyId(comp).subscribe(response => {
      this.companyArr = response
      if (this.companyArr.length > 0)
        if (utc < this.companyArr[0].licence_Exp_Date) {
          this.validate = true;
        }
        else {
          alert("Company Licence is Expired")
          this.validate = false
        }
      if (this.companyArr.length == 0) {
        alert("Company Code is Invalid")
      }
    })

  }

  getLoginId() {
    var utc = new Date().toJSON().slice(0, 10)
    let userId = this.loginForm.value.user
    this.loginService.searchLoginId(userId).subscribe(response => {
      this.userArr = response;
      if (this.userArr.length > 0)
        if (this.userArr[0].status == false) {
          alert("LoginId is Inactive")
          this.validate = false;
        }
        else {
          this.validate = true;
        }

      if (this.userArr.length == 0) {
        alert("LoginId is Invalid")
        this.validate = false
      }
    })

  }
  movementArr
  checkOnLogin(companyId, employeeId) {
    // //console.log("companyId ", companyId, "  employeeId  ", employeeId)
    this.loginService.checkOnLogin(companyId, employeeId).subscribe(
      data => {
        this.movementArr = data
        // //console.log("in checkOnLogin movementArr ", this.movementArr)
        if (this.movementArr.length > 0) {
          // ApproveMovementComponent.loginArr = this.movementArr;
          this.route.navigate(['/pages/movement-maintenance/appMov']);
        }
        else {
          this.route.navigate(['/pages/welcome']);
        }
      }
    )
  }

  openRegistration() {
    this.route.navigate(['/create/user/create-login'])
    // this.route.navigate(['/pages/payrole/user-registaration'])
  }
  forgotPwd() {
    this.route.navigate(['/pages/settings/forgot-password'])

  }
}
